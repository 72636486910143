// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Language detector
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translation files
import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';
import translationPT from './locales/pt/translation.json';

// Configure resources
const resources = {
    en: { translation: translationEN },
    es: { translation: translationES },
    pt: { translation: translationPT },
};

// Retrieve the language from localStorage, if it exists
const savedLanguage = localStorage.getItem('selected_language') || 'es'; // Default to 'es' (Spanish) if no language is stored

i18n
    .use(LanguageDetector) // Detect user language
    .use(initReactI18next)  // Pass i18n to react-i18next
    .init({
        resources,
        lng: savedLanguage, // Use the saved language or default to Spanish
        fallbackLng: 'es', // Default language if detection fails
        interpolation: {
            escapeValue: false, // Not needed for React
        },
    });

export default i18n;
