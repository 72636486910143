import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./Common/ProtectedRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dashboard, ProductAnalytics, UserAnalytics } from "components/Analytics";

// Import MainLayout
const MainLayout = lazy(() => import("./layout"));

// Preload function
const preloadComponent = (importFn) => {
  const Component = lazy(importFn);
  Component.preload = importFn;
  return Component;
};

// Preload function to trigger component loading
const preloadRoute = (Component) => {
  if (Component.preload) {
    Component.preload();
  }
};

// Define components with preloading
const ChatPanel = preloadComponent(() =>
  import("./components/User/Chat/ChatPage")
);
const SelectedChatPanel = preloadComponent(() =>
  import("./components/User/Chat/SelectedChat")
);
const SidebarMenu = preloadComponent(() => import("./components/Admin/index"));
const LoginPage = preloadComponent(() => import("./components/Login/Login"));
const ProductPage = preloadComponent(() =>
  import("./components/User/Product/ProductPage")
);
const ProductDetails = preloadComponent(() =>
  import("./components/User/Product/ProductDetails")
);
const AddProductForm = preloadComponent(() =>
  import("./components/User/Product/AddProductForm")
);
const NotFound = preloadComponent(() => import("./Common/NotFound"));
const ProductCatalog = preloadComponent(() =>
  import("./components/User/Product/ProductCatalog")
);
const AllFeedback = preloadComponent(() =>
  import("./components/Admin/pages/AllFeedback")
);
// const Analytics = preloadComponent(() => import("./components/Analytics"));
// Update the analytics imports to use preloadComponent


// LoadingSpinner component
const LoadingSpinner = () => (
  <div className="min-h-screen flex items-center justify-center">
    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
  </div>
);

// Wrap component with MainLayout for protected routes
const LayoutWrapper = ({ component: Component, ...rest }) => {
  return (
    <MainLayout>
      <Component {...rest} />
    </MainLayout>
  );
};

const App = () => {
  // Preload main routes
  React.useEffect(() => {
    preloadRoute(ChatPanel);
    preloadRoute(LoginPage);
  }, []);

  return (
    <Router>
      <div className="App">
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          closeOnClick
          pauseOnHover
          draggable
          newestOnTop={false}
        />

        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<LoginPage />} />
            <Route path="*" element={<NotFound />} />

            {/* Protected routes with MainLayout */}
            <Route
              path="/chat"
              element={
                <ProtectedRoute
                  element={<LayoutWrapper component={ChatPanel} />}
                  onEnter={() => {
                    preloadRoute(SelectedChatPanel);
                    preloadRoute(ProductPage);
                  }}
                />
              }
            />
            <Route
              path="/chat/:id"
              element={
                <ProtectedRoute
                  element={<LayoutWrapper component={SelectedChatPanel} />}
                />
              }
            />
            <Route
              path="/users"
              element={
                <ProtectedRoute
                  element={<LayoutWrapper component={SidebarMenu} />}
                />
              }
            />
            <Route
              path="/ProductPage"
              element={
                <ProtectedRoute
                  element={<LayoutWrapper component={ProductPage} />}
                />
              }
            />
            <Route
              path="/product/:product_code"
              element={
                <ProtectedRoute
                  element={<LayoutWrapper component={ProductDetails} />}
                />
              }
            />
            <Route
              path="/AddProduct"
              element={
                <ProtectedRoute
                  element={<LayoutWrapper component={AddProductForm} />}
                />
              }
            />
            <Route
              path="/products"
              element={
                <ProtectedRoute
                  element={<LayoutWrapper component={ProductCatalog} />}
                />
              }
            />
            <Route
              path="/all"
              element={
                <ProtectedRoute
                  element={<LayoutWrapper component={AllFeedback} />}
                />
              }
            />
            {/* <Route
              path="/analytics"
              element={
                <ProtectedRoute
                  element={<LayoutWrapper component={Analytics} />}
                />
              }
            /> */}
            <Route
              path="/analytics"
              element={
                <ProtectedRoute
                  element={<LayoutWrapper component={Dashboard} />}
                />
              }
            />
            <Route
              path="/analytics/users"
              element={
                <ProtectedRoute
                  element={<LayoutWrapper component={UserAnalytics} />}
                />
              }
            />
            <Route
              path="/analytics/products"
              element={
                <ProtectedRoute
                  element={<LayoutWrapper component={ProductAnalytics} />}
                />
              }
            />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
};

export default App;
