import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { copvelAxios } from "service/api";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';

// Icons
import SidebarCollpase from "assets/icons/SidebarCollpase";
import SidebarIcon from "assets/icons/SidebarIcon";
import ChatScreenFrameOrange from "assets/icons/ChatScreenFrameOrange";

// Components

import TopProductsTable from "./components/TopProductsTable";
import AnalyticsModal from "./components/AnalyticsModal";
import { role } from "constant/global";
import Pagination from "./components/Pagination";
import { setCollapseDrawer, setCollapseSideBar } from "components/Redux/userSlice";

const ProductAnalytics = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [showBrandsModal, setShowBrandsModal] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { collapseSideBar } = useSelector((state) => state.user);
  const [productPageSize, setProductPageSize] = useState(20);
  const [brandPageSize, setBrandPageSize] = useState(20);
  const [productCurrentPage, setProductCurrentPage] = useState(1);
  const [brandCurrentPage, setBrandCurrentPage] = useState(1);
  const [productTotalPages, setProductTotalPages] = useState(1);
  const [brandTotalPages, setBrandTotalPages] = useState(1);
  
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;

  useEffect(() => {
    if (
      userData?.organizations[0]?.role !== role.ADMIN &&
      userData?.organizations[0]?.role !== role.OWNER
    ) {
      navigate("/chat");
    }
  }, [userData, navigate]);

  const fetchAnalytics = async () => {
    try {
      setLoading(true);
      const orgId = localStorage.getItem("orgId");
      const response = await copvelAxios.get(`/analytics/products/summary?organization_id=${orgId}`);
      setAnalyticsData(response.data);
    } catch (error) {
      console.error("Error fetching analytics:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllProducts = async (page = 1, pageSize = 20) => {
    try {
      const orgId = localStorage.getItem("orgId");
      const response = await copvelAxios.get(
        `/analytics/products?organization_id=${orgId}&page=${page}&page_size=${pageSize}`
      );
      setAllProducts(response.data.items);
      setProductTotalPages(Math.ceil(response.data.total / pageSize));
    } catch (error) {
      console.error("Error fetching all products:", error);
    }
  };

  const fetchAllBrands = async (page = 1, pageSize = 20) => {
    try {
      const orgId = localStorage.getItem("orgId");
      const response = await copvelAxios.get(
        `/analytics/brands?organization_id=${orgId}&page=${page}&page_size=${pageSize}`
      );
      setAllBrands(response.data.items);
      setBrandTotalPages(Math.ceil(response.data.total / pageSize));
    } catch (error) {
      console.error("Error fetching all brands:", error);
    }
  };

  useEffect(() => {
    fetchAnalytics();
  }, []);

  const toggleSidebar = () => {
    dispatch(setCollapseSideBar(!collapseSideBar));
  };

  const toggleDrawer = () => {
    dispatch(setCollapseSideBar(!collapseSideBar));
    dispatch(setCollapseDrawer(true));
  };

  const BrandsChart = ({ data }) => {
    return (
      <div className="h-[400px] w-full">
        <ResponsiveContainer>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="suggestion_count" fill="#8884d8" name={t("suggestions")} />
            <Bar dataKey="unique_users" fill="#82ca9d" name={t("uniqueUsers")} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ChatScreenFrameOrange />
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-white">
      {/* {!collapseSideBar && <Sidebar />} */}

      <div className="flex-grow p-6 bg-white shadow-md mt-4 md:mt-0 flex flex-col overflow-y-auto">
        {/* Header */}
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-3">
            {collapseSideBar && (
              <span
                className="hover:cursor-pointer hidden md:block"
                onClick={toggleSidebar}
              >
                <SidebarCollpase />
              </span>
            )}
            <span
              className="block md:hidden hover:cursor-pointer"
              onClick={toggleDrawer}
            >
              <SidebarIcon />
            </span>
            <h2 className="text-xl font-bold leading-[22px] font-Montserrat">
              {t("productAnalytics")}
            </h2>
          </div>
        </div>

        {/* Brand Analytics */}
        <div className="bg-white rounded-lg shadow p-4 mb-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">{t("topBrands")}</h3>
            <button
              onClick={() => {
                setShowBrandsModal(true);
                fetchAllBrands();
              }}
              className="text-fontBlue hover:underline"
            >
              {t("viewAll")}
            </button>
          </div>
          <BrandsChart data={analyticsData?.top_brands || []} />
        </div>

        {/* Products Table */}
        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">{t("topProducts")}</h3>
            <button
              onClick={() => {
                setShowProductsModal(true);
                fetchAllProducts();
              }}
              className="text-fontBlue hover:underline"
            >
              {t("viewAll")}
            </button>
          </div>
          <TopProductsTable products={analyticsData?.top_products || []} />
        </div>

        {/* Modals */}
        <AnalyticsModal
          isOpen={showProductsModal}
          onClose={() => setShowProductsModal(false)}
          title={t("allProducts")}
        >
          <TopProductsTable products={allProducts} />
          <Pagination
            currentPage={productCurrentPage}
            totalPages={productTotalPages}
            pageSize={productPageSize}
            onPageChange={(page) => {
              setProductCurrentPage(page);
              fetchAllProducts(page, productPageSize);
            }}
            onPageSizeChange={(size) => {
              setProductPageSize(size);
              setProductCurrentPage(1);
              fetchAllProducts(1, size);
            }}
          />
        </AnalyticsModal>

        <AnalyticsModal
          isOpen={showBrandsModal}
          onClose={() => setShowBrandsModal(false)}
          title={t("allBrands")}
        >
          <TopProductsTable products={allBrands} />
          <Pagination
            currentPage={brandCurrentPage}
            totalPages={brandTotalPages}
            pageSize={brandPageSize}
            onPageChange={(page) => {
              setBrandCurrentPage(page);
              fetchAllBrands(page, brandPageSize);
            }}
            onPageSizeChange={(size) => {
              setBrandPageSize(size);
              setBrandCurrentPage(1);
              fetchAllBrands(1, size);
            }}
          />
        </AnalyticsModal>
      </div>
    </div>
  );
};

export default ProductAnalytics; 