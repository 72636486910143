import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { copvelAxios } from "service/api";

import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip
} from 'recharts';

// Icons
import SidebarCollpase from "assets/icons/SidebarCollpase";
import SidebarIcon from "assets/icons/SidebarIcon";
import ChatScreenFrameOrange from "assets/icons/ChatScreenFrameOrange";

// Components
import StatCard from "./components/StatCard";
import TopUsersTable from "./components/TopUsersTable";
import TopProductsTable from "./components/TopProductsTable";
import { role } from "constant/global";
import { setCollapseDrawer, setCollapseSideBar } from "components/Redux/userSlice";

const QueryDistributionChart = ({ textQueries, voiceQueries }) => {
  const data = [
    { name: 'Text Queries', value: textQueries },
    { name: 'Voice Queries', value: voiceQueries }
  ];

  const COLORS = ['#0088FE', '#00C49F'];

  return (
    <div className="h-[300px] w-full">
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { collapseSideBar } = useSelector((state) => state.user);
  
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;

  useEffect(() => {
    if (
      userData?.organizations[0]?.role !== role.ADMIN &&
      userData?.organizations[0]?.role !== role.OWNER
    ) {
      navigate("/chat");
    }
  }, [userData, navigate]);

  const fetchAnalytics = async () => {
    try {
      setLoading(true);
      const orgId = localStorage.getItem("orgId");
      const response = await copvelAxios.get(`/analytics/summary?organization_id=${orgId}`);
      setAnalyticsData(response.data);
    } catch (error) {
      console.error("Error fetching analytics:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAnalytics();
  }, []);

  const toggleSidebar = () => {
    dispatch(setCollapseSideBar(!collapseSideBar));
  };

  const toggleDrawer = () => {
    dispatch(setCollapseSideBar(!collapseSideBar));
    dispatch(setCollapseDrawer(true));
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ChatScreenFrameOrange />
      </div>
    );
  }

  return (
    // h-screen
    <div className="flex  bg-white">
      {/* {!collapseSideBar && <Sidebar />} */}

      <div className="flex-grow p-6 bg-white shadow-md mt-4 md:mt-0 flex flex-col">
        {/* Header */}
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-3">
            {collapseSideBar && (
              <span
                className="hover:cursor-pointer hidden md:block"
                onClick={toggleSidebar}
              >
                <SidebarCollpase />
              </span>
            )}
            <span
              className="block md:hidden hover:cursor-pointer"
              onClick={toggleDrawer}
            >
              <SidebarIcon />
            </span>
            <h2 className="text-xl font-bold leading-[22px] font-Montserrat">
              {t("analytics")}
            </h2>
          </div>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-6">
          <StatCard
            title={t("totalQueries")}
            value={analyticsData?.total_queries || 0}
          />
          <StatCard
            title={t("totalConversations")}
            value={analyticsData?.total_conversations || 0}
          />
          <StatCard
            title={t("textQueries")}
            value={analyticsData?.text_queries || 0}
          />
          <StatCard
            title={t("voiceQueries")}
            value={analyticsData?.voice_queries || 0}
          />
          <StatCard
            title={t("uniqueUsers")}
            value={analyticsData?.unique_users || 0}
          />
          <StatCard
            title={t("avgQueriesPerConversation")}
            value={analyticsData?.avg_queries_per_conversation?.toFixed(2) || 0}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="bg-white rounded-lg shadow p-4">
            <h3 className="text-lg font-semibold mb-4">{t("queryDistribution")}</h3>
            <QueryDistributionChart 
              textQueries={analyticsData?.text_queries || 0}
              voiceQueries={analyticsData?.voice_queries || 0}
            />
          </div>
          {/* Other charts/stats can go here */}
        </div>

        {/* Top Users and Products Section */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="bg-white rounded-lg shadow p-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">{t("topUsers")}</h3>
              <button
                onClick={() => navigate("/analytics/users")}
                className="text-fontBlue hover:underline"
              >
                {t("viewAll")}
              </button>
            </div>
            <TopUsersTable users={analyticsData?.top_users || []} />
          </div>

          <div className="bg-white rounded-lg shadow p-4">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">{t("topProducts")}</h3>
              <button
                onClick={() => navigate("/analytics/products")}
                className="text-fontBlue hover:underline"
              >
                {t("viewAll")}
              </button>
            </div>
            <TopProductsTable products={analyticsData?.top_products || []} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard; 