import React from 'react';

const RightArrow = ({ stroke="#C9C9C9" }) => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.1665 7.00008H12.8332M12.8332 7.00008L6.99984 1.16675M12.8332 7.00008L6.99984 12.8334" stroke={stroke} stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default RightArrow;
