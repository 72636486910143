import React from 'react';

const LeftArrow = ({ stroke="#C9C9C9" }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.8332 7.00008H1.1665M1.1665 7.00008L6.99984 12.8334M1.1665 7.00008L6.99984 1.16675" stroke={stroke} stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export default LeftArrow;
