import React from 'react';
const ChatScreenFrameOrange = ({ className, height }) => {
    return (
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_347_3075)">
                <rect width="52" height="52" rx="26" fill="#E9500E" />
                <mask id="mask0_347_3075" maskUnits="userSpaceOnUse" x="-12" y="4" width="76" height="50">
                    <path d="M63.9806 4H-11.2578V54H63.9806V4Z" fill="white" />
                </mask>
                <g mask="url(#mask0_347_3075)">
                    <path d="M-51.5244 58.4084C-51.5244 30.7692 2.38774 8.37939 68.8978 8.37939H106.895V10.2355H68.8978C9.77775 10.2355 -38.1409 31.4942 -38.1409 57.7123C-38.1409 58.2924 -38.1118 58.8724 -38.0827 59.4525H-51.4953C-51.4953 59.1045 -51.5244 58.7564 -51.5244 58.4084Z" fill="white" />
                </g>
                <mask id="mask1_347_3075" maskUnits="userSpaceOnUse" x="-12" y="4" width="76" height="50">
                    <path d="M63.9806 4H-11.2578V54H63.9806V4Z" fill="white" />
                </mask>
                <g mask="url(#mask1_347_3075)">
                    <path d="M-24.7578 57.6833C-24.7578 32.8863 17.1674 12.7587 68.9266 12.7587H106.924V15.2819L68.9266 15.2529C24.5865 15.2529 -11.3743 34.3074 -11.3743 57.7993C-11.3743 58.3503 -11.3452 58.9304 -11.3161 59.4814H-24.6705C-24.7287 58.8724 -24.7578 58.2633 -24.7578 57.6833Z" fill="white" />
                </g>
                <mask id="mask2_347_3075" maskUnits="userSpaceOnUse" x="-12" y="4" width="76" height="50">
                    <path d="M63.9806 4H-11.2578V54H63.9806V4Z" fill="white" />
                </mask>
                <g mask="url(#mask2_347_3075)">
                    <path d="M2.00977 58.4085C2.00977 36.3087 31.9771 18.3853 68.9272 18.3853H106.925V21.5175H68.9272C39.3671 21.5175 15.3932 38.3098 15.3932 59.0175V59.4815H2.03885C2.00976 59.1045 2.00977 58.7565 2.00977 58.4085Z" fill="white" />
                </g>
                <mask id="mask3_347_3075" maskUnits="userSpaceOnUse" x="-12" y="4" width="76" height="50">
                    <path d="M63.9806 4H-11.2578V54H63.9806V4Z" fill="white" />
                </mask>
                <g mask="url(#mask3_347_3075)">
                    <path d="M68.9271 6.49417C-4.97301 6.49417 -64.8786 29.986 -64.8786 58.9884V59.4524H-85.623C-84.5465 29.464 -15.7671 5.24707 68.9271 5.24707L106.925 5.27607V6.52317L68.9271 6.49417Z" fill="white" />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_347_3075">
                    <rect width="52" height="52" rx="26" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ChatScreenFrameOrange;

