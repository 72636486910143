import React from "react";
import { useTranslation } from "react-i18next";

const TopUsersTable = ({ users }) => {
  const { t } = useTranslation();

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {t("user")}
            </th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {t("queries")}
            </th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {t("conversations")}
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {users.map((user, index) => (
            <tr key={user.user_id}>
              <td className="px-4 py-2 whitespace-nowrap">
                <div className="flex items-center">
                  <div className="flex-shrink-0 h-8 w-8 bg-gray-800 rounded-full flex items-center justify-center text-white text-sm">
                    {user.full_name.split(" ").map(n => n[0]).join("")}
                  </div>
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">
                      {user.full_name}
                    </div>
                    <div className="text-sm text-gray-500">{user.email}</div>
                  </div>
                </div>
              </td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                {user.query_count}
              </td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                {user.conversation_count}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopUsersTable; 