import React from "react";
import { useTranslation } from "react-i18next";

const TopProductsTable = ({ products }) => {
  const { t } = useTranslation();

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {t("product")}
            </th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {t("suggestions")}
            </th>
            <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {t("uniqueUsers")}
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {products.map((product) => (
            <tr key={product.product_id}>
              <td className="px-4 py-2 whitespace-nowrap">
                <div className="text-sm font-medium text-gray-900">
                  {product.name}
                </div>
              </td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                {product.suggestion_count}
              </td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                {product.unique_users}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TopProductsTable; 