export const validPaths = ["", "chat", "selectedChat","users","products","all","analytics"];
export const role = {
    OWNER: "owner",
    ADMIN: "admin",
  };

// const commonProps = {
//     isPopoverOpen,
//     setIsPopoverOpen,
//     logoutFn,
//     openLanguageModal,
//     closeLanguageModal,
//     languages,
//     capitalizedFullName,
//     userData,
//     pathName,
//     chatHistory,
//     isReloading,
//     chatHistoryLoad,
//     navigate,
//     dispatch,
//     setSelectedChat,
//     handleDeleteClick,
//     SidebarIcon,
//     SettingDots,
//     logoOrange,
//     NewChatIcon,
//     ProductIcon,
//     Members,
//     Delete,
//   };