
import axios from "axios";
import { toast } from "react-toastify";
import { API_URL, COPEVAL_API_URL } from "../constants";
import { handleLogout } from "../utility/CommonFunction";



// Main API instance 
const chatAxios = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// COPEVAL API instance
const copvelAxios = axios.create({
  baseURL: COPEVAL_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptor for main API
chatAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("user_session_token"); // Main token from localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
   
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor for COPEVAL API
copvelAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("user_auth_token"); // Separate token for COPEVAL
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
   
    return config;
  },
  (error) => Promise.reject(error)
);


const handleResponseError = (error) => {
  if (error.response) {
    const status = error.response.status;
    switch (status) {
      case 401:
        toast.error("Token Expired!!");
        handleLogout()
        break;
      case 403:
        toast.error("Your session has expired or you don't have the required permissions to access this resource.");
        handleLogout()
        break;
      default:
        break;
    }
  }
  return Promise.reject(error);
};


chatAxios.interceptors.response.use((response) => response, handleResponseError);
copvelAxios.interceptors.response.use((response) => response, handleResponseError);


export { chatAxios, copvelAxios };
