import React from "react";
import { useTranslation } from "react-i18next";
import LeftArrow from "assets/icons/LeftArrow";
import RightArrow from "assets/icons/RightArrow";

const Pagination = ({ currentPage, totalPages, onPageChange, pageSize, onPageSizeChange }) => {
  const { t } = useTranslation();
  const pageSizes = [20, 50, 100];

  return (
    <div className="flex flex-col sm:flex-row justify-between items-center mt-4 gap-4">
      <div className="flex items-center gap-2">
        <span className="text-sm text-gray-600">{t("itemsPerPage")}</span>
        <select
          value={pageSize}
          onChange={(e) => onPageSizeChange(Number(e.target.value))}
          className="border rounded p-1"
        >
          {pageSizes.map(size => (
            <option key={size} value={size}>{size}</option>
          ))}
        </select>
      </div>
      
      <div className="flex items-center gap-2">
        <button
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="flex items-center px-3 py-1 border rounded disabled:opacity-50"
        >
        <LeftArrow stroke={currentPage === 1?"#C9C9C9":"#25408f"}/>
          <span className="ml-1">{t("previous")}</span>
        </button>
        
        <span className="text-sm">
          {t("page")} {currentPage} {t("of")} {totalPages}
        </span>
        
        <button
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="flex items-center px-3 py-1 border rounded disabled:opacity-50"
        >
          <span className="mr-1">{t("next")}</span>
          <RightArrow />
        </button>
      </div>
    </div>
  );
};

export default Pagination; 