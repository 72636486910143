import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { copvelAxios } from "service/api";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar
} from 'recharts';
import moment from 'moment';

// Icons
import SidebarCollpase from "assets/icons/SidebarCollpase";
import SidebarIcon from "assets/icons/SidebarIcon";
import ChatScreenFrameOrange from "assets/icons/ChatScreenFrameOrange";

// Components

import TopUsersTable from "./components/TopUsersTable";
import { role } from "constant/global";
import AnalyticsModal from "./components/AnalyticsModal";
import Pagination from "./components/Pagination";
import { setCollapseDrawer, setCollapseSideBar } from "components/Redux/userSlice";

const UserAnalytics = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const [analyticsData, setAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { collapseSideBar } = useSelector((state) => state.user);
  
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;

  const [showUsersModal, setShowUsersModal] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [userCurrentPage, setUserCurrentPage] = useState(1);
  const [userPageSize, setUserPageSize] = useState(20);
  const [userTotalPages, setUserTotalPages] = useState(1);

  useEffect(() => {
    if (
      userData?.organizations[0]?.role !== role.ADMIN &&
      userData?.organizations[0]?.role !== role.OWNER
    ) {
      navigate("/chat");
    }
  }, [userData, navigate]);

  const fetchAnalytics = async () => {
    try {
      setLoading(true);
      const orgId = localStorage.getItem("orgId");
      const response = await copvelAxios.get(`/analytics/users/summary?organization_id=${orgId}`);
      setAnalyticsData(response.data);
    } catch (error) {
      console.error("Error fetching analytics:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAnalytics();
  }, []);

  const toggleSidebar = () => {
    dispatch(setCollapseSideBar(!collapseSideBar));
  };

  const toggleDrawer = () => {
    dispatch(setCollapseSideBar(!collapseSideBar));
    dispatch(setCollapseDrawer(true));
  };

  const fetchAllUsers = async (page = 1, pageSize = 20) => {
    try {
      const orgId = localStorage.getItem("orgId");
      const response = await copvelAxios.get(
        `/analytics/users?organization_id=${orgId}&page=${page}&page_size=${pageSize}`
      );
      setAllUsers(response.data.items);
      setUserTotalPages(Math.ceil(response.data.total / pageSize));
    } catch (error) {
      console.error("Error fetching all users:", error);
    }
  };

  const DailyStatsChart = ({ data }) => {
    const formattedData = data.map(item => ({
      ...item,
      date: moment(item.timestamp).format('MMM DD'),
      queries: item.total_queries,
      conversations: item.total_conversations,
      activeUsers: item.active_users
    }));

    return (
      <div className="h-[400px] w-full">
        <ResponsiveContainer>
          <LineChart data={formattedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="queries" stroke="#8884d8" name="Queries" />
            <Line type="monotone" dataKey="conversations" stroke="#82ca9d" name="Conversations" />
            <Line type="monotone" dataKey="activeUsers" stroke="#ffc658" name="Active Users" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  };

  const MonthlyStatsChart = ({ data }) => {
    const formattedData = data.map(item => ({
      ...item,
      month: moment(item.timestamp).format('MMM YYYY'),
      queries: item.total_queries,
      conversations: item.total_conversations,
      uniqueUsers: item.unique_users
    }));

    return (
      <div className="h-[400px] w-full">
        <ResponsiveContainer>
          <BarChart data={formattedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="queries" fill="#8884d8" name="Queries" />
            <Bar dataKey="conversations" fill="#82ca9d" name="Conversations" />
            <Bar dataKey="uniqueUsers" fill="#ffc658" name="Unique Users" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <ChatScreenFrameOrange />
      </div>
    );
  }

  return (
    <div className="flex h-screen bg-white">
      {/* {!collapseSideBar && <Sidebar />} */}

      <div className="flex-grow p-6 bg-white shadow-md mt-4 md:mt-0 flex flex-col overflow-y-auto">
        {/* Header */}
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-3">
            {collapseSideBar && (
              <span
                className="hover:cursor-pointer hidden md:block"
                onClick={toggleSidebar}
              >
                <SidebarCollpase />
              </span>
            )}
            <span
              className="block md:hidden hover:cursor-pointer"
              onClick={toggleDrawer}
            >
              <SidebarIcon />
            </span>
            <h2 className="text-xl font-bold leading-[22px] font-Montserrat">
              {t("userAnalytics")}
            </h2>
          </div>
        </div>

        {/* Stats Charts */}
        <div className="grid grid-cols-1 gap-6 mb-6">
          <div className="bg-white rounded-lg shadow p-4">
            <h3 className="text-lg font-semibold mb-4">{t("dailyStats")}</h3>
            <DailyStatsChart data={analyticsData?.daily_stats || []} />
          </div>

          <div className="bg-white rounded-lg shadow p-4">
            <h3 className="text-lg font-semibold mb-4">{t("monthlyStats")}</h3>
            <MonthlyStatsChart data={analyticsData?.monthly_stats || []} />
          </div>
        </div>

        {/* Users Table */}
        <div className="bg-white rounded-lg shadow p-4">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-semibold">{t("topUsers")}</h3>
            <button
              onClick={() => {
                setShowUsersModal(true);
                fetchAllUsers();
              }}
              className="text-fontBlue hover:underline"
            >
              {t("viewAll")}
            </button>
          </div>
          <TopUsersTable users={analyticsData?.top_users || []} />
        </div>
      </div>

      <AnalyticsModal
        isOpen={showUsersModal}
        onClose={() => setShowUsersModal(false)}
        title={t("allUsers")}
      >
        <TopUsersTable users={allUsers} />
        <Pagination
          currentPage={userCurrentPage}
          totalPages={userTotalPages}
          pageSize={userPageSize}
          onPageChange={(page) => {
            setUserCurrentPage(page);
            fetchAllUsers(page, userPageSize);
          }}
          onPageSizeChange={(size) => {
            setUserPageSize(size);
            setUserCurrentPage(1);
            fetchAllUsers(1, size);
          }}
        />
      </AnalyticsModal>
    </div>
  );
};

export default UserAnalytics; 